* {
    box-sizing: border-box;
}
html {
    background: rgb(0, 54, 97);
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
a:-webkit-any-link {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}
p:not(:first-of-type) {
    margin-top: 16px;
}
